import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import SubHeader from '../components/SubHeaderProfile';
import trainer1 from '../images/trainer/trainer1.png';
import trainer2 from '../images/trainer/trainer2.png';
import trainer3 from '../images/trainer/trainer3.png';
import trainer4 from '../images/trainer/trainer4.png';
import trainer5 from '../images/trainer/trainer5.png';
// import * as ROUTES from '../src/constants/routes';
import Digital from '../components/DigitalView';

class DigitalPage extends React.Component {

  render() {
    return (
      <Fragment>
      <SubHeader />
      <title>トレーナー 國學院大學久我山高等学校 バスケットボール部</title>
  <div className="tr-background">

  <div className="tr-container">
  <div className="trainer-area-main" id="jiko">
  <h2 className="content-title-white-profile">トレーナー</h2>
  <div className="profile-maintext-digital">

  <div className="card-master">
  <div className="card-position">

  <div className="card-content-tr">
      <img className="card-img" alt="sakai" src={trainer1} />
      <div className="no-square">TR</div>
      <figcaption className="card-profile">
      <div className="card-name-tr"><div className="card-subname-tr">羽生智久</div></div>
      <div className="card-script">
      <div className="card-text-mng">役職<div className="card-subtext-tr">トレーナー統括</div></div>
      </div>
      </figcaption>
  </div>

  <div className="card-content-tr">
      <img className="card-img" alt="sakai" src={trainer2} />
      <div className="no-square">TR</div>
      <figcaption className="card-profile">
      <div className="card-name-tr"><div className="card-subname-tr">入江孝政</div></div>
      <div className="card-script">
      <div className="card-text-mng">役職<div className="card-subtext-tr">ヘッドトレーナー</div></div>
      </div>
      </figcaption>
  </div>

  <div className="card-content-tr">
      <img className="card-img" alt="sakai" src={trainer3} />
      <div className="no-square">TR</div>
      <figcaption className="card-profile">
      <div className="card-name-tr"><div className="card-subname-tr">川島大輝</div></div>
      <div className="card-script">
      <div className="card-text-mng">役職<div className="card-subtext-tr">トレーナー</div></div>
      </div>
      </figcaption>
  </div>

  <div className="card-content-tr">
      <img className="card-img" alt="sakai" src={trainer4} />
      <div className="no-square">TR</div>
      <figcaption className="card-profile">
      <div className="card-name-tr"><div className="card-subname-tr">今川真琴</div></div>
      <div className="card-script">
      <div className="card-text-mng">役職<div className="card-subtext-tr">トレーナー</div></div>
      </div>
      </figcaption>
  </div>

  <div className="card-content-tr">
      <img className="card-img" alt="sakai" src={trainer5} />
      <div className="no-square">TR</div>
      <figcaption className="card-profile">
      <div className="card-name-tr"><div className="card-subname-tr">藤井梓</div></div>
      <div className="card-script">
      <div className="card-text-mng">役職<div className="card-subtext-tr">トレーナー</div></div>
      </div>
      </figcaption>
  </div>


  </div>
  </div>


  </div>

</div>
</div>

<div className="tr-link">
<a href="https://hone.co.jp" target="_blank" rel="noopener noreferrer">
HSコーポレーションはこちらから
</a>
</div>

  </div>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <DigitalPage />
  </Layout>
);
